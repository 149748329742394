:root {
  --size: 1;
  --background: #000;
  --color1: #e7d155;
  --color2: #eb5454;
  --color3: #e7d155;
  --color4: #eb5454;
}
.swiper {
  width: 100vw;
  height: 100%;
  padding: "5pt";
  max-width: 900pt;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.swiper-button-next {
  color: white;
}

.swiper-button-prev {
  color: white;
}
.swiper-button-disabled {
  color: transparent;
}

/* Position text in the middle of the page/image */
.bg-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
}

.faq{
  position: relative;
  transition: all 300ms cubic-bezier(0.03, 0.98, 0.53, 0.99) 1s;
  transition-property: all;
}

.ticket {
  position: relative;
  transition: all 300ms cubic-bezier(0.03, 0.98, 0.53, 0.99) 0s;
  background: linear-gradient(
    to right,
    var(--color1),
    var(--color2),
    var(--color3),
    var(--color4)
  );
  border-radius: 20px;
  padding: 5px;
}

.ticket:before,
.ticket:after {
  content: "";
  display: block;
  position: absolute;
  top: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 2;
}

.ticket:before {
  background: var(--color1);
  left: -30px;
}

.ticket:after {
  right: -30px;
  background: var(--color4);
}

.ticket-content-wrapper {
  height: 100%;
  position: relative;
  background: var(--background);
  border-radius: 15px;
  padding: 10px;
}

.mintPending{
  background: linear-gradient(
    to right,
    var(--color1),
    var(--color2),
    var(--color3),
    var(--color4)
  );
}

.ticker { width: 90%; }